import React from 'react';
import { FormattedMessage } from 'react-intl';
import JobWorkflow from './Popups/JobWorkflow';
import NameWithAlias from '../../shared/Components/ListItems/NameWithAlias';
import ItemsCellList from '../../shared/Components/ListItems/ItemsCellList';
import CpTimeAgo from '../../shared/Components/Date/CpTimeAgo';
import Popover from '../../shared/Components/Messaging/Popover';
import StatusBubble from '../../shared/Components/ListItems/StatusBubble';
import { JobsCategories } from '../duck/helpers/JobsCategories';


// TODO: put in utils and use it throughout the project
const parseSafely = (toBeParsedData, fallback) => {
    if (toBeParsedData) {
        try {
            return JSON.parse(toBeParsedData);
        } catch (e) {
            return fallback;
        }
    }
};

export const listRows = (classes, sites) => {
    const categories = new JobsCategories();
    return [
        {
            key: 'spacerLeft',
            hideMobile: false,
            hideTablet: false,
            hideRightPane: false,
            numeric: false,
            label: '',
            headClassName: 'tableCellSpacer'
        },
        {
            key: 'name',
            hideMobile: false,
            hideTablet: false,
            hideRightPane: false,
            hideRightPaneTabLandscape: false,
            numeric: false,
            label: <FormattedMessage id="cpJobs.jobName" defaultMessage="Job Name" />,
            headClassName: 'tableHeadCellMinWidth'
        },
        {
            key: 'site',
            hideMobile: true,
            hideTablet: false,
            hideRightPane: false,
            hideRightPaneTabLandscape: false,
            numeric: false,
            label: <FormattedMessage id="cpJobs.site" defaultMessage="Site" />,
            headClassName: 'tableHeadCellMinWidth',
            customTemplate: job => {
                const site = sites.find(site => site.site === job.site && site.cpId === job.cpId);
                if (site)
                    return <NameWithAlias name={job.site} alias={site.alias} classes={classes} />;
            }
        },
        {
            key: 'printer',
            hideMobile: true,
            hideTablet: false,
            hideRightPane: false,
            hideRightPaneTabLandscape: true,
            numeric: false,
            label: <FormattedMessage id="cpJobs.printer" defaultMessage="Printer" />,
            headClassName: 'tableHeadCellMinWidth'
        },
        {
            key: 'images',
            hideMobile: true,
            hideTablet: true,
            hideRightPane: true,
            hideRightPaneTabLandscape: true,
            numeric: false,
            label: <FormattedMessage id="cpJobs.images" defaultMessage="Images" />,
            headClassName: 'tableHeadCellMinWidth',
            customTemplate: job => (
                <ItemsCellList items={parseSafely(job.images, ['-'])} alias="images" />
            )
        },
        {
            key: 'workflows',
            hideMobile: true,
            hideTablet: true,
            hideRightPane: true,
            hideRightPaneTabLandscape: true,
            numeric: false,
            label: (
                <FormattedMessage id="cpJobs.workflow" defaultMessage="Workflow" />
            ),
            headClassName: 'tableHeadCellMinWidth',
            customTemplate: job => (
                <JobWorkflow workflow={parseSafely(job.workflow, [])} />
            )
        },
        {
            key: 'created',
            hideMobile: false,
            hideTablet: false,
            hideRightPane: false,
            hideRightPaneTabLandscape: false,
            numeric: false,
            label: <FormattedMessage id="cpJobs.created" defaultMessage="Created" />,
            headClassName: 'tableHeadCellMinWidth',
            customTemplate: job => (
                <Popover
                    content={new Date(job.created).toLocaleString()}
                    position="top"
                >
                    <span>
                        <CpTimeAgo date={job.created} />
                    </span>
                </Popover>
            )
        },
        {
            key: 'state',
            hideMobile: false,
            hideTablet: false,
            hideRightPane: false,
            hideRightPaneTabLandscape: false,
            numeric: false,
            label: <FormattedMessage id="cpJobs.state" defaultMessage="State" />,
            headClassName: 'tableBubbleHeadCellMinWidth',
            customTemplate: job => <StatusBubble status={job.state} categories={categories} classes={classes} />
        },
        {
            key: 'spacerRight',
            hideMobile: true,
            hideTablet: true,
            hideRightPane: false,
            hideRightPaneTabLandscape: false,
            numeric: false,
            label: '',
            headClassName: 'tableCellSpacerMobile'
        }
    ];
};