import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography/index';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LeftIcon from '../../../assets/icons/LeftIcon';
import { getCustomerHfWfs } from '../../Jobs/duck/jobsActions';
import LoadingButton from '../../shared/Components/Buttons/LoadingButton';
import { addCpCustomerFlow } from '../../shared/duck/actions/pairingInfoActions';
import Utils from '../../shared/duck/helpers/Utils';
import withIntl from '../../shared/Localization';
import ServiceContext from '../../shared/Services/ServiceContext';
import { getAddFlowEvent } from '../../shared/Services/tracking';
import styles from '../../shared/Styles/styles';

class FlowManager extends React.Component {
  static contextType = ServiceContext

  state = {
    hubSelection: [],
    remoteClientsSelection: [],
    selectedFlowName: '',
    selectedHub: '',
    flowNameErrorText: '',
    hubSelectionErrorText: '',
    filteredWorkflows: [],
    selectedWorkflow: '',
    selectedRemoteClient: '',
    createFlowAllowed: true,
    encryptionSupported: true
  }

  static getDerivedStateFromProps (props, state) {
    props.sites.forEach(item => {
      if (item.isHub && item.flowLicensed && item.licensed) {
        if (!state.hubSelection.some(i => i.cpId === item.cpId)) {
          state.hubSelection.push(item)
        }
      }
      if (!state.remoteClientsSelection.some(i => i.cpId === item.cpId)) {
        if (item.flowLicensed && item.licensed)
          state.remoteClientsSelection.push(item)
      }
    })

    return state
  }

  async componentDidMount () {
    if (this.props.hfwfs.length === 0)
      await this.props.getCustomerHfWfs()
  }

  handleFlowNameChange = e => {
    this.setState({ selectedFlowName: e.target.value })
  }

  handleHubChange = e => {
    const ch = this.state.hubSelection.find(h => h.cpId === e.target.value)

    if (!this.context.featureFlagService.areFlowActionsSupported(ch)) {
      this.setState({ createFlowAllowed: false, selectedHub: e.target.value })
      return
    }

    this.setState({ selectedHub: e.target.value, createFlowAllowed: true })
  }

  handleRemoteClientsChange = async e => {
    const rc = this.state.remoteClientsSelection.find(r => r.cpId === e.target.value)

    if (!this.context.featureFlagService.areFlowActionsSupported(rc)) {
      this.setState({ createFlowAllowed: false, selectedRemoteClient: e.target.value })
      return
    }

    if (!this.context.featureFlagService.isFilesEncryptionSupported(rc))
      this.setState({ encryptionSupported: false, createFlowAllowed: true, selectedRemoteClient: e.target.value })
    else
      this.setState({ selectedRemoteClient: e.target.value, createFlowAllowed: true, encryptionSupported: true })
  }

  openWorkflowsDropdown = async () => {
    await this.props.getCustomerHfWfs()

    const wf = this.props.hfwfs.filter(f => f.cpId === this.state.selectedRemoteClient)

    this.setState({ filteredWorkflows: wf })
  }

  handleWorkflowsChange = e => {
    this.setState({ selectedWorkflow: e.target.value })
  }

  validate = () => {
    let flowNameErrorText = ''
    let hubSelectionErrorText = ''

    if (!this.state.selectedFlowName) {
      flowNameErrorText = this.context.translationService.tr('flowManager.flowNameMissingErrorText')
    }

    if (!this.state.selectedHub) {
      hubSelectionErrorText = this.context.translationService.tr('flowManager.flowHubMissingErrorText')
    }

    this.setState({
      flowNameErrorText: flowNameErrorText,
      hubSelectionErrorText: hubSelectionErrorText
    })

    return !flowNameErrorText && !hubSelectionErrorText
  }

  async addFlow () {
    this.context.trackingService.trackEvent(getAddFlowEvent('Flow Management'))

    if (this.validate()) {
      await this.props.addCpCustomerFlow({
        customerId: this.props.auth.currentUser.username,
        name: this.state.selectedFlowName.trim(),
        hubId: this.state.selectedHub,
        clients: [ this.state.selectedRemoteClient ],
        targetHfwfId: this.state.selectedWorkflow,
        targetWfName: this.getSelectedWorkflowName()
      })

      this.clearModal()
      this.props.close()
    }
  }

  getSelectedWorkflowName = () => {
    const selectedWf = this.state.filteredWorkflows.find(wf => wf.id === this.state.selectedWorkflow)
    return selectedWf ? selectedWf.WorkflowName : ''
  }

  clearModal = () => {
    this.setState({
      selectedFlowName: null,
      selectedHub: null,
      selectedRemoteClient: null,
      selectedWorkflow: null,
      createFlowAllowed: true,
      encryptionSupported: true
    })
  }

  cancel = () => {
    this.clearModal()
    this.props.close()
  }

  render () {
    const { classes } = this.props
    const modalClass = !this.state.createFlowAllowed || !this.state.encryptionSupported ? classes.flowModalStyleError : classes.flowModalStyle

    return (
      <Modal open={ this.props.visible }
        className={ classes.zIndex }>
        <div className={ modalClass }>
          <Typography className={ classes.inviteUserTitle }>
            <FormattedMessage
              id="flowManager.addFlow"
              defaultMessage="ADD FLOW"
            />
          </Typography>

          <div className={ classes.userInviteUserMobileModal }>
            <Typography className={ classes.inviteUserSelectBoxTitle }>
              <FormattedMessage
                id="flowManager.flowName"
                defaultMessage="Flow Name"
              />
            </Typography>
            <TextField
              fullWidth
              id="flow-name"
              name="flow-name"
              type="text"
              value={ this.state.selectedFlowName || '' } // Initial value when rendered
              className={ classNames(classes.inviteInputBox, classes.formControl) }
              FormHelperTextProps={ { className: classes.formError } }
              onChange={ this.handleFlowNameChange }
              onBlur={ this.handleFlowNameChange }
              onClick={ this.handleFlowNameChange }
              variant="outlined"
              error={ !this.state.flowNameErrorText }
              helperText={ this.state.flowNameErrorText }
              InputLabelProps={ { shrink: true } }
            />

            <Typography className={ classes.inviteUserSelectBoxTitle }>
              <FormattedMessage
                id="flowManager.colorHub"
                defaultMessage="Color Hub"
              />
            </Typography>
            <Select
              disableUnderline={ true }
              value={ this.state.selectedHub || '' }
              onChange={ this.handleHubChange }
              displayEmpty
              name="hubs"
              IconComponent={ LeftIcon }
              classes={ {
                root: classes.cpSelectBox + ' ' + classes.inviteUserFormControlSelect,
                selectMenu: classes.cpSelectBoxContent
              } }
              MenuProps={ {
                classes: {
                  paper: classes.cpSelectMenuPaper
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                MenuListProps: {
                  className: classes.cpSelectList
                }
              } }
              renderValue={ selected => {
                if (!selected) {
                  return this.context.translationService.tr('flowManager.selectionNoneHub')
                }

                let selectedDisplayValue = null

                this.state.hubSelection.forEach(j => {
                  if (selected === j.cpId) {
                    selectedDisplayValue = j.alias || j.site
                  }
                })

                return selectedDisplayValue
              } }
            >
              { this.state.hubSelection &&
                this.state.hubSelection
                  .sort(Utils.sortObjectByKey('site', false))
                  .map(item => {
                    if (item.site) {
                      return (
                        <MenuItem
                          className={ classes.cpSelectMenuItem }
                          key={ item.cpId }
                          value={ item.cpId }
                          classes={ { selected: classes.cpSelectSelected } }
                          selected={
                            this.state.selectedHub &&
                            this.state.selectedHub.cpId === item.cpId
                          }
                        >
                          {item.alias ? item.alias : item.site }
                        </MenuItem>
                      )
                    }
                  }) }
            </Select>
            <Typography className={ classes.inviteUserSelectBoxTitle }>
              <FormattedMessage
                id="flowManager.remoteClients"
                defaultMessage="Remote Clients"
              />
            </Typography>
            <Select
              disableUnderline={ true }
              value={ this.state.selectedRemoteClient || '' }
              onChange={ this.handleRemoteClientsChange }
              displayEmpty
              name="remote_clients"
              IconComponent={ LeftIcon }
              classes={ {
                root: classes.cpSelectBox + ' ' + classes.inviteUserFormControlSelect,
                selectMenu: classes.cpSelectBoxContent
              } }
              MenuProps={ {
                classes: {
                  paper: classes.cpSelectMenuPaper
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                MenuListProps: {
                  className: classes.cpSelectList
                }
              } }
              renderValue={ selected => {
                if (!selected) {
                  return this.context.translationService.tr('flowManager.selectionNoneRemoteClients')
                }

                let selectedDisplayValue = null

                this.state.remoteClientsSelection.forEach(j => {
                  if (selected === j.cpId) {
                    selectedDisplayValue = j.alias || j.site
                  }
                })

                return selectedDisplayValue
              } }
            >
              { this.state.remoteClientsSelection &&
                this.state.remoteClientsSelection
                  .sort(Utils.sortObjectByKey('site', false))
                  .map((item, index) => {
                    if (item.site) {
                      return (
                        <MenuItem
                          className={ classes.cpSelectMenuItem }
                          key={ index }
                          value={ item.cpId }
                          classes={ { selected: classes.cpSelectSelected } }
                          selected={
                            this.state.selectedRemoteClient &&
                            this.state.selectedRemoteClient.cpId === item.cpId
                          }
                        >
                          {item.alias || item.site }
                        </MenuItem>
                      )
                    }
                  }) }
            </Select>

            <Typography className={ classes.inviteUserSelectBoxTitle }>
              <FormattedMessage
                id="flowManager.workflow"
                defaultMessage="Workflows"
              />
            </Typography>
            <Select
              disableUnderline={ true }
              value={ this.state.selectedWorkflow || '' }
              onChange={ this.handleWorkflowsChange }
              onOpen={ this.openWorkflowsDropdown }
              displayEmpty
              name="workflows"
              IconComponent={ LeftIcon }
              classes={ {
                root: classes.cpSelectBox + ' ' + classes.inviteUserFormControlSelect,
                selectMenu: classes.cpSelectBoxContent
              } }
              MenuProps={ {
                classes: {
                  paper: classes.cpSelectMenuPaper
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                MenuListProps: {
                  className: classes.cpSelectList
                }
              } }
              renderValue={ selected => {
                if (!selected) {
                  return this.context.translationService.tr('flowManager.selectedWorkflow')
                }

                let selectedDisplayValue = null

                this.state.filteredWorkflows.forEach(j => {
                  if (selected === j.id) {
                    selectedDisplayValue = j.WorkflowName
                  }
                })

                return selectedDisplayValue
              } }
            >
              { this.state.filteredWorkflows &&
                this.state.filteredWorkflows
                  .sort(Utils.sortObjectByKey('WorkflowName', false))
                  .map((item, index) => {
                    if (item.WorkflowName) {
                      return (
                        <MenuItem
                          className={ classes.cpSelectMenuItem }
                          key={ index }
                          value={ item.id }
                          classes={ { selected: classes.cpSelectSelected } }
                          selected={
                            this.state.selectedWorkflow &&
                            this.state.selectedWorkflow.id === item.id
                          }
                        >
                          {item.WorkflowName }
                        </MenuItem>
                      )
                    }
                  }) }
            </Select>
          </div>
          { !this.state.createFlowAllowed && this.state.selectedRemoteClient &&
            <Typography className={ classes.inviteUserSelectBoxTitleError }>
              <FormattedMessage
                id="flowManager.flowsCreationError"
                defaultMessage="Error! Your ColorProof version is smaller then 5.13.0.99 and do not support a flows creation."
              />
            </Typography>
          }
          { !this.state.encryptionSupported && this.state.createFlowAllowed &&
            <Typography className={ classes.inviteUserSelectBoxTitleError }>
              <FormattedMessage
                id="flowManager.encryptionSupportWarning"
                defaultMessage="Warning! Your ColorProof version is smaller then 5.13.1.0 and does not support the encryption."
              />
            </Typography>
          }
          <div className={ classes.inviteUserButtons }>
            <span className={ classes.printerActions }>
              <Button
                id="flowsList.cancelCreateCustomerFlow"
                color="secondary"
                className={ classes.inviteModalCancelButton }
                fontFamily="Segoe UI"
                onClick={ () => this.cancel() }
              >
                <FormattedMessage
                  id="flowManager.cancel"
                  defaultMessage="CANCEL"
                />
              </Button>
              <LoadingButton
                id="flowsList.createCustomerFlow"
                className={ classes.inviteButton }
                fullWidth
                type="submit"
                color="primary"
                variant="outlined"
                disableRipple
                disabled={ !this.state.selectedHub || (!this.state.createFlowAllowed && this.state.selectedRemoteClient) }
                onClick={ () => this.addFlow() }
              >
                <FormattedMessage
                  id="flowManager.add"
                  defaultMessage="ADD"
                />
              </LoadingButton>
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: state.authentication,
    hfwfs: state.jobs.hfwfs
  }
}

FlowManager.propTypes = {
  auth: PropTypes.object.isRequired,
  getCustomerHfWfs: PropTypes.func.isRequired,
  addCpCustomerFlow: PropTypes.func.isRequired
}

export default withIntl(
  withStyles(styles)(
    withRouter(
      connect(
        mapStateToProps,
        {
          getCustomerHfWfs,
          addCpCustomerFlow
        }
      )(FlowManager)
    )
  )
)