class NotificationService {
  static instance

  constructor() {
    // noinspection ES6ModulesDependencies
    if (NotificationService.instance) {
      // noinspection ES6ModulesDependencies
      return NotificationService.instance
    }

    NotificationService.instance = this
  }  

  // When the buttonAction is null, the component will perform a close automatically.
  showNotification(
    notificationComponentParent,
    type,
    text,
    layout = '3col', // Can be 2col or 3col
    showBlanket = false,
    buttonLabel = null,
    buttonAction = null,
    buttonCancel = null,
    offset = 0,
    timeout = 8000
  ) {
    notificationComponentParent.setState({
      notification: {
        type: type,
        text: text,
        layout: layout,
        showBlanket: showBlanket,
        buttonLabel: buttonLabel,
        buttonAction: buttonAction,
        buttonCancel: buttonCancel,
        offset: offset,
        timeout: timeout
      }
    })
  }
}

export { NotificationService }