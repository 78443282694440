import { NavigationService } from './NavigationService';
import { NotificationService } from './NotificationService';
import { TrackingService } from './TrackingService.ts';
import { SessionService } from './SessionService';
import { FeatureFlagService } from './FeatureFlagService';
import { LeftSideBar } from './LeftSideBar';
import { TranslationService } from './TranslationService';

// Currently all services are implemented as singletons itself, so the Container is just a factory right now.
// Later the objects itself will be stored and depndencies will be managed here.
export default class Container {
    static TrackingService() {
        let service = TrackingService.getInstance(); // NOTE: this is now a Singleton written in ts! no new keyword instatiation

        if (!service.isInitialized()) {
            throw new Error('TrackingService has not been initialized properly.');
        }

        return service;
    }

    static NavigationService(history) {
        let service = new NavigationService(history, TrackingService.getInstance());

        if (!service.isInitialized()) {
            throw new Error('NavigationService has not been initialized properly.');
        }

        return service;
    }

    static NotificationService() {
        return new NotificationService();
    }

    static SessionService() {
        return new SessionService();
    }

    static FeatureFlagService() {
        return new FeatureFlagService();
    }

    static TranslationService() {
        let service = new TranslationService();

        if (!service.isInitialized()) {
            throw new Error('TranslationService has not been initialized properly.');
        }

        return service;
    }

    static LeftSideBar() {
        return new LeftSideBar();
    }
}