import * as types from './types'
import { generateInitialValuesForChannelMapping, generateChannels } from './handlers/colorMappingHandlers'

let initialState = {
  cpJobs: [],
  cloudJobIds: [],
  addCpJobSubscriptions: [],
  deleteJobSubscriptions: [],
  updateJobSubscriptions: [],
  colorMappingChannelJobSubscriptions: [],
  jobDetailsUpdated: false,
  hfwfs: [],
  jobDetails: [],
  actions: [],
  uploadInfo: {},
  jobDetailsImages: [],
  imageDetails: [],
  fileUrl: '',
  certificateData: {},
  initialValuesFromState: {},
  channelValuesFromState: [],
  graphQLErrors: null,
  isFetching: false,
}

export default function jobs ( state = initialState, action ) {
  switch ( action.type ) {
    case types.GET_CUSTOMER_CP_JOBS_SUCCESS:
      return Object.assign( {}, state, {
        cpJobs: action.res.data.listCustomerCpJobs.items
      } )
    case types.GET_CP_CERTIFICATE_SUCCESS:
      return Object.assign( {}, state, {
        certificateData: action.res.data.getCertificate
      } )
    case types.SUBSCRIBE_TO_CP_JOB_ADD_SUCCESS:

      var previousJobs = state.cpJobs.filter( j => j.id !== action.data[0].id );

      return Object.assign( {}, state, {
        addCpJobSubscriptions: action.subscriptions,
        cpJobs: [ ...action.data, ...previousJobs ],
        jobDetailsUpdated: false
      } )
    case types.SUBSCRIBE_TO_CP_JOB_DELETE_SUCCESS:
      return Object.assign( {}, state, {
        deleteJobSubscriptions: action.subscriptions,
        cpJobs: state.cpJobs.filter( j => j.id !== action.data.id ),
        jobDetailsUpdated: false
      } )
    case types.SUBSCRIBE_TO_CP_JOB_UPDATE:
      return Object.assign( {}, state, {
        jobDetailsUpdated: false
      } )
    case types.SUBSCRIBE_TO_CP_JOB_UPDATE_SUCCESS:
      //TODO: return a new object instead of modify an existing one
      state.cpJobs.forEach( j => {
        if ( j.id === action.data.id ) {
          j.name = action.data.name
          j.state = action.data.state
          j.userAction = action.data.userAction
          j.userActionMessage = action.data.userActionMessage
          j.copies = action.data.copies
          j.created = action.data.created
          j.cpId = action.data.cpId
          j.images = action.data.images
          j.printer = action.data.printer
          j.site = action.data.site
          j.workflow = action.data.workflow
        }
      } )
      return Object.assign( {}, state, {
        updateJobSubscriptions: action.subscriptions,
        jobDetailsUpdated: true
      } )

      case types.SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING:
        return Object.assign( {}, state, {
        } )

      case types.SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_SUCCESS:
        return Object.assign( {}, state, {
          colorMappingChannelJobSubscriptions: action.subscriptions,
          cloudJobIds: [...state.cloudJobIds, action.data.jobId]
        } )

    case types.LIST_CUSTOMER_HFWFS_SUCCESS:
      return Object.assign( {}, state, {
        hfwfs: action.res.data.listCustomerHfWfs.items,
        isFetching: action.res.loading
      } )
    case types.LIST_CUSTOMER_CP_JOB_DETAILS_SUCCESS:
      return Object.assign( {}, state, {
        jobDetails: action.res.data.listCustomerCpJobDetailsByJob.items
      } )
    case types.GET_COLOR_CHANNEL_MAPPING_DETAILS:
      return Object.assign( {}, state, {
        isFetching: action.loading
      } )
    case types.GET_CUSTOMER_CP_JOB_IDS_SUCCESS:
      return Object.assign( {}, state, {
        cloudJobIds: action.res.data.findJobs
      } )
    case types.GET_COLOR_CHANNEL_MAPPING_DETAILS_SUCCESS:

      let initialValues = {}
      let channels = []
      if(action.res.data.getColorChannelMapping) {
        initialValues = generateInitialValuesForChannelMapping(action.res.data.getColorChannelMapping);
        channels = generateChannels(action.res.data.getColorChannelMapping);
      }

      return Object.assign( {}, state, {
        initialValuesFromState: initialValues,
        channelValuesFromState: channels,
        isFetching: action.res.loading,
        errorColorChannelMapping: null
      } )
    case types.GET_COLOR_CHANNEL_MAPPING_DETAILS_FAILURE:
        return Object.assign( {}, state, { 
          initialValuesFromState: {},
          channelValuesFromState: [],
          isFetching: false,
          errorColorChannelMapping: action.err
      } )
    case types.ADD_ACTION_SUCCESS:
      return Object.assign( {}, state, {
        actions: action.res.data
      } )
    case types.CUSTOMER_CREATE_ORDER_SUCCESS:
      return Object.assign( {}, state, {
        uploadInfo: action.res.data.customerCreateOrder
      } )
    case types.DOWNLOAD_JOBDETAILS_IMAGE_SUCCESS:
      return Object.assign( {}, state, {
        jobDetailsImages: action.jobDetailsImages
      } )
    case types.DOWNLOAD_JOBDETAILS_FILE_SUCCESS:
      return Object.assign( {}, state, {
        fileUrl: action.fileUrl
      } )
    case types.GET_IMAGE_DETAILS_SUCCESS:
      return Object.assign( {}, state, {
        imageDetails: action.res.data.ImageDetails
      } )
    case types.CLEAR_JOBS_DATA:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}