import * as types from './types'
import { handleAction } from './handlers/updateHandler'

let initialState = {
  actions: [],
  customerPrinters: [],
  addPrinterSubscriptions: [],
  deletePrinterSubscriptions: [],
  updatePrinterOverviewSubscriptions: [],
  updatePrinterDetailSubscriptions: [],
  printerDetailsUpdated: false
}

export default function printers (state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_PRINTERS_SUCCESS:
      return Object.assign({}, state, {
        customerPrinters: action.res.data.listCustomerPrinters.items,
        printerDetailsUpdated: false
      })
    case types.ADD_ACTION_SUCCESS:
      const updatedPrinters = handleAction(action.res.data.addAction, state.customerPrinters)

      return Object.assign({}, state, {
        actions: action.res.data,
        customerPrinters: updatedPrinters,
        printerDetailsUpdated: true
      })
    case types.SUBSCRIBE_TO_ADD_PRINTER_SUCCESS:
      var previousPrinters = state.customerPrinters.filter( p => p.id !== action.data[0].id );
      return Object.assign({}, state, {
        addPrinterSubscriptions: action.subscriptions,
        customerPrinters: [ ...action.data, ...previousPrinters ],
        printerDetailsUpdated: false
      })
    case types.SUBSCRIBE_TO_DELETE_PRINTER_SUCCESS:
      return Object.assign({}, state, {
        deletePrinterSubscriptions: action.subscriptions,
        customerPrinters: state.customerPrinters.filter(p => p.printerId !== action.data.printerId),
        printerDetailsUpdated: false
      })
    case types.SUBSCRIBE_TO_UPDATE_PRINTER_SUCCESS:
      //TODO: return a new object instead of modify an existing one
      state.customerPrinters.forEach(p => {
        if (p.printerId === action.data.printerId && p.cpId === action.data.cpId) {
          p.name = action.data.name
          p.status = action.data.status
          p.site = action.data.site
          p.media = action.data.media
          p.mediaId = action.data.mediaId
          p.isCalibrated = action.data.isCalibrated
          p.lastCalibrationDate = action.data.lastCalibrationDate
        }
      })
      return Object.assign({}, state, {
        updatePrinterOverviewSubscriptions: action.subscriptions,
        printerDetailsUpdated: true
      })
    case types.SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_SUCCESS:
      //TODO: return a new object instead of modify an existing one
      state.customerPrinters.forEach(p => {
        if (p.printerId === action.data.printerId && p.cpId === action.data.cpId) {
          p.errorMessage = action.data.errorMessage
          p.printerType = action.data.printerType
          p.inks = action.data.inks
          p.mediaSize = action.data.mediaSize
          p.inkSet = action.data.inkSet
          p.medias = action.data.medias
          p.mediaSizeId = action.data.mediaSizeId
          p.caliSets = action.data.caliSets
        }
      })
      return Object.assign({}, state, {
        updatePrinterDetailSubscriptions: action.subscriptions,
        printerDetailsUpdated: true
      })
    case types.SUBSCRIBE_TO_PRINTERDETAIL_UPDATE:
    case types.SUBSCRIBE_TO_UPDATE_PRINTER:
      return Object.assign({}, state, {
        printerDetailsUpdated: false
      })
    case types.CLEAR_PRINTERS_DATA:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
