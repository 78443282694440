import * as React from 'react';
//TODO: 
// this file is the app Context it should be named liked that!
// This should be a ts file
// Provide appContext type and define here everything 
// create a default AppContext interface
// change it to named import !

export interface IAppContext {
    // trackingService: TrackingService | null
}
export const defaultContext: IAppContext = {
    // trackingService: null
};

const AppContext = React.createContext<IAppContext>(defaultContext);

export default AppContext;
