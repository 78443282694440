type TrackingEventName =
    'OpenTermAndService'
    | 'SeeNextJob'
    | 'SeePreviousJob'
    | 'PrintJob'
    | 'PauseJob'
    | 'DeleteJob'
    | 'ClosePane'
    | 'OpenNewFileDialog'
    | 'DropletMenuLogOut'
    | 'CloseDropletMenu'
    | 'SubmitMediaChanges'
    | 'ClearMediaChanges'
    | 'SubmitCalisetChanges'
    | 'ClearCalisetChanges'
    | 'StartPrinter'
    | 'PausePrinter'
    | 'OpenPrinterActionsMobile'
    | 'ClosePrinterActionsMobile'
    | 'ClosePrinterRightPane'
    | 'OpenCalisetSelectBox'
    | 'OpenMediaChangeSelectBox'
    | 'AddFlow'
    | 'ChangeFlowName'
    | 'DeleteFlow'
    | 'ColorHubCheckboxClick'
    | 'DeleteUserPairing'
    | 'OpenHelpCenter'
    | 'JobRightPaneOpenOrChange'
    | 'SetFilterChipsValue'
    | 'UploadFilesViaDropzone'
    | 'UploadFileViaNewFileDialogInDropZone'
    | 'ChangeCurrentViewInDropletMenu'
    | 'BackToIndexPage'
    | 'ClickNewFileInDropzone'
    ;

export interface TrackingEvent {
    name: TrackingEventName;
    description?: string;
}
export function checkDisableTracking(email: string | undefined, ignore: Array<string>) {
    if (!email) {
        return;
    }
    if (ignore.find(ignorePattern => email.indexOf(ignorePattern) > -1)) {
        localStorage.setItem('plausible_ignore', 'true');
    } else {
        localStorage.removeItem('plausible_ignore');
    }
}

export const getClickNewFileInDropzoneEvent = (): TrackingEvent => {
    return {
        name: 'ClickNewFileInDropzone'
    }
}
export const getChangeCurrentViewInDropletMenuEvent = (): TrackingEvent => {
    return {
        name: 'ChangeCurrentViewInDropletMenu'
    }
}
export const getOpenTermAndServiceEvent = (): TrackingEvent => {
    return {
        name: 'OpenTermAndService'
    }
}
export const getBackToIndexPageEvent = (description: string): TrackingEvent => {
    return {
        name: 'BackToIndexPage',
        description: description
    };
}
export const getSeeNextJobEvent = (description: string): TrackingEvent => {
    return {
        name: 'SeeNextJob',
        description: description
    };
}
export const getSeePreviousJobEvent = (description: string): TrackingEvent => {
    return {
        name: 'SeePreviousJob',
        description: description
    };
}
export const getPrintJobEvent = (description: string): TrackingEvent => {
    return {
        name: 'PrintJob',
        description: description
    };
}
export const getPauseJobEvent = (description: string): TrackingEvent => {
    return {
        name: 'PauseJob',
        description: description
    };
}
export const getDeleteJobEvent = (description: string): TrackingEvent => {
    return {
        name: 'DeleteJob',
        description: description
    };
}
export const getClosePaneEvent = (description: string): TrackingEvent => {
    return {
        name: 'ClosePane',
        description: description
    };
}
export const getOpenNewFileDialogEvent = (description: string): TrackingEvent => {
    return {
        name: 'OpenNewFileDialog',
        description: description
    };
}
export const getDropletMenuLogOutEvent = (): TrackingEvent => {
    return {
        name: 'DropletMenuLogOut',
    };
}
export const getCloseDropletMenuEvent = (): TrackingEvent => {
    return {
        name: 'OpenNewFileDialog',
    };
}
export const getSubmitMediaChangesEvent = (description: string): TrackingEvent => {
    return {
        name: 'SubmitMediaChanges',
        description: description, 
    };
}
export const getClearMediaChangesEvent = (description: string): TrackingEvent => {
    return {
        name: 'ClearMediaChanges',
        description: description, 
    };
}
export const getSubmitCalisetChangesEvent = (description: string): TrackingEvent => {
    return {
        name: 'SubmitCalisetChanges',
        description: description, 
    };
}
export const getClearCalisetChangesEvent = (description: string): TrackingEvent => {
    return {
        name: 'ClearCalisetChanges',
        description: description, 
    };
}
export const getStartPrinterEvent = (description: string): TrackingEvent => {
    return {
        name: 'StartPrinter',
        description: description, 
    };
}
export const getPausePrinterEvent = (description: string): TrackingEvent => {
    return {
        name: 'PausePrinter',
        description: description, 
    };
}
export const getOpenPrinterActionsMobileEvent = (description: string): TrackingEvent => {
    return {
        name: 'OpenPrinterActionsMobile',
        description: description, 
    };
}
export const getClosePrinterActionsMobileEvent = (description: string): TrackingEvent => {
    return {
        name: 'ClosePrinterActionsMobile',
        description: description, 
    };
}
export const getOpenCalisetSelectBoxEvent = (description: string): TrackingEvent => {
    return {
        name: 'OpenCalisetSelectBox',
        description: description, 
    };
}
export const getOpenMediaChangeSelectBoxEvent = (description: string): TrackingEvent => {
    return {
        name: 'OpenMediaChangeSelectBox',
        description: description, 
    };
}
export const getClosePrinterRightPaneEvent = (): TrackingEvent => {
    return {
        name: 'ClosePrinterRightPane',
    };
}
export const getChangeFlowNameEvent = (description: string): TrackingEvent => {
    return {
        name: 'ChangeFlowName',
        description: description, 
    };
}
export const getAddFlowEvent = (description: string): TrackingEvent => {
    return {
        name: 'AddFlow',
        description: description, 
    };
}
export const getColorHubCheckboxClickEvent = (): TrackingEvent => {
    return {
        name: 'ColorHubCheckboxClick',
    };
}
export const getDeleteFlowEvent = (description: string): TrackingEvent => {
    return {
        name: 'DeleteFlow',
        description: description, 
    };
}
export const getOpenHelpCenterEvent = (description: string): TrackingEvent => {
    return {
        name: 'OpenHelpCenter',
        description: description, 
    };
}
export const getDeleteUserPairingEvent = (): TrackingEvent => {
    return {
        name: 'DeleteUserPairing',
    };
}
export const getUploadFilesViaDropzoneEvent = (): TrackingEvent => {
    return {
        name: 'UploadFilesViaDropzone',
    };
}
export const getUploadFileViaNewFileDialogInDropZoneEvent = (): TrackingEvent => {
    return {
        name: 'UploadFileViaNewFileDialogInDropZone',
    };
}
export const getJobRightPaneOpenOrChangEvent = (description: string): TrackingEvent => {
    return {
        name: 'JobRightPaneOpenOrChange',
        description: description, 
    };
}
export const getSetFilterChipsValueEvent = (description: string): TrackingEvent => {
    return {
        name: 'SetFilterChipsValue',
        description: description, 
    };
}