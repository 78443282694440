import React from 'react';
import { Switch, Route } from 'react-router';
import Authenticator from '../Authentication/Authenticator';
import AppRoot from '../AppRoot';
import * as constants from '../shared/duck/helpers/constants';


export const Routes = () => {
    const authRoot = { pathname: '/auth' };
    return (
        <Switch>
            <Route
                exact
                //When you pass a single string, the route will match only that specific path.
                //When you pass an array of paths, the component will be rendered if any of those paths
                path={[
                    constants.GA_HOME,
                    constants.GA_LOGIN,
                    constants.GA_TERMS_OF_SERVICE
                ]}
                location={authRoot}
            >
                <Authenticator />
            </Route>
            <Route
                exact
                path={[
                    constants.GA_JOBS,
                    constants.GA_PRINTERS,
                    constants.GA_FLOW_ORDERS,
                    constants.GA_SETTINGS_SYSTEMS,
                    constants.GA_SETTINGS_USERS,
                    constants.GA_SETTINGS_FLOWS,
                    constants.GA_JOB_ID
                ]}
            >
                <AppRoot />
            </Route>
        </Switch>
    );
};
export default Routes;